import React from 'react';
import { NavLink, withRouter, Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import classes from './Toolbar.module.css';
import NavigationItems from '../NavigationItems/NavigationItems';
import Logo from '../../UI/Logo/Logo';
import fire from '../../../config/Fire';

const Toolbar = (props) => {

    const logout = () => {
        fire.auth().signOut();
        toast.success('Vous êtes maintenant déconnecté.');
        props.history.replace('/');
    };

    return(
        <header className={classes.Toolbar}>
            <div className="container">
                <div className={classes.agencement}>
                    <div className={classes.logo}>
                        <NavLink to='/'>
                            <Logo height='40px'/>
                        </NavLink>
                    </div>
                    <nav className={classes.nav}>
                        <NavigationItems connected={props.connected} />
                    </nav>

                    {/* Is user connected ? */}
                    {props.connected ?
                        // <div className={[classes.dropdownText, 'dropdown'].join(' ')}>
                        <div className="dropdown">
                            {/* Trigger */}
                            <span className={classes.dropdownToggle} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                {props.username}
                                &nbsp;&nbsp;
                                <i className="fas fa-angle-down"></i>
                            </span>
                            
                            {/* Dropdown menu */}
                            <div className={[classes.dropdownHeader, 'dropdown-menu dropdown-menu-right p-0'].join(' ')} arial-labelledby="user-nav">
                                <div className={classes.dropdownArrow}>
                                    <i className="fas fa-caret-up m-0" style={{'fontSize': '70px'}}></i>
                                </div>
                                {/* Items */}
                                <span className="dropdown-item p-0">
                                    <Link to='/parameters'>
                                        <div className={classes.dropdownItem}>
                                            <i className="fas fa-cogs mr-3 text-secondary text-center" style={{'width': '15px'}}></i>
                                            Paramètres
                                        </div>
                                    </Link>
                                </span>
                                <div className="dropdown-divider my-0"></div>
                                <span className="dropdown-item py-2" onClick={logout}>
                                    <i className="fas fa-sign-out-alt mr-3 text-secondary text-center" style={{'width': '15px'}}></i>
                                    Déconnexion
                                </span>
                            </div>
                        </div>
                    : null }
                </div>
            </div>
        </header>
    );

}

export default withRouter(Toolbar);