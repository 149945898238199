import React, { useState, useEffect } from 'react';
import classes from '../HiraganaTraining/KanaTraining.module.css';

import { hiragana, romaji } from '../../../config/Kana';
import Button from '../../../components/UI/Button/Button';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import fire from '../../../config/Fire';
import moment from 'moment';
import Spinner from '../../../components/UI/Spinner/Spinner';
// import moment from 'moment';

const HiraganaFurious = (props) => {
    
    // State: Score
    const [score, setScore] = useState(null);
    // State: answers
    const [answers, setAnswers] = useState({
        a: '',
        b: '',
        c: '',
        d: ''
    });
    // State: minuter
    let [minuter, setMinuter] = useState(0);
    // State: current kana to find
    const [kana, setKana] = useState({
        kana: '',
        index: ''
    });
    // State: lastKana
    const [lastKanas, setLastKanas] = useState([]);
    // State: wrongAnswers' Indexes
    const [wrongAnswers, setWrongAnswers] = useState([]);
    const [gameStarted, setGameStarted] = useState(true);
    const [loading, setLoading] = useState(false);
    // State: actualScore
    let [actualScore, setActualScore] = useState(0);
    // Variables
    let [timerOn, setTimerOn] = useState(null);

    useEffect(() => {
        loadKana();
        setTimerOn(setInterval(timer, 1000));
    }, []);

    // Personal random method
    const random = (min, max) => {
        return Math.floor(Math.random() * (max - min)) + min;
    }

    // Loads new kana
    const loadKana = () => {
        // Loads a new kana
        let newKana = '';
        do {
            const index = random(0, hiragana.length);
            newKana = {kana: hiragana[index], index: index}
            setKana(newKana);
        } while(lastKanas[lastKanas.length - 1] == newKana.index);

        // Updates the last kana's array
        lastKanas.push(newKana.index);

        // Fill the answers
        let answersArray = [];
        for(let i = 0; i < 4; i++){
            let answerIndex;
            do {
                answerIndex = random(0, hiragana.length);
            } while(answerIndex == newKana.index || answersArray.includes(answerIndex));

            // Adds a valid random answer
            answersArray[i] = answerIndex;
        }

        // Selects the answer's location
        const answerLocation = random(0, 4);
        // Adds the answer
        answersArray[answerLocation] = newKana.index;
        
        // Set the new state
        setAnswers({
            a: romaji[answersArray[0]],
            b: romaji[answersArray[1]],
            c: romaji[answersArray[2]],
            d: romaji[answersArray[3]]
        });
    };

    const answerHandler = (event) => {
        const indexTapped = romaji.indexOf(event.target.value);
        var newActualScore = actualScore;
        
        if(indexTapped == kana.index) {
            newActualScore += 1;
            setActualScore(actualScore + 1);
            document.querySelector('.kana').style.color = 'green';
        }
        else {
            setActualScore(0);
            document.querySelector('.kana').style.color = 'red';
            wrongAnswers.push(kana.index);
        }

        if(newActualScore >= 50) {
            setLoading(true);
            clearInterval(timerOn);

            const db = fire.firestore();
            const user = fire.auth().currentUser;
            const timeInHuman = moment.utc(minuter*1000).format('mm:ss');

            db.collection('scores').doc().set({
                modeSlug: 'hiragana-furious',
                score: 'Série terminée en ' + timeInHuman + 's',
                uid: user.uid,
                dateCreation: new Date()
            })
            .then(() => {
                toast.success('Challenge terminé en ' + timeInHuman + 's !');
                setWrongAnswers([...new Set(wrongAnswers)]);
                setGameStarted(false);
                setLoading(false);
            });
        }
        else {
            loadKana();
        }
    };

    // Minuter
    const timer = () => {
        let seconds = minuter++;
        setMinuter(seconds);
    };

    return(
        <React.Fragment>
            <div className="row">
                <div className="col text-center">
                    <div className="display-4">
                        { moment.utc(minuter*1000).format('mm:ss') }s
                    </div>
                    <span>Série actuelle: {actualScore} / 50</span>
                </div>
            </div>

            {
                loading ?
                    <Spinner />
                :
                    gameStarted ?
                        <React.Fragment>
                        <div className="row">
                            <div className={[classes.Kana, "col text-center kana"].join(' ')}>
                                { kana.kana }
                            </div>
                        </div>
                        
                        <div className="row">
                            <div className="col text-center">
                                <Button btnType="Shy" value={answers.a} clicked={(e) => answerHandler(e)}>{answers.a}</Button>
                                <Button btnType="Shy" value={answers.b} clicked={(e) => answerHandler(e)}>{answers.b}</Button>
                                <Button btnType="Shy" value={answers.c} clicked={(e) => answerHandler(e)}>{answers.c}</Button>
                                <Button btnType="Shy" value={answers.d} clicked={(e) => answerHandler(e)}>{answers.d}</Button>
                            </div>
                        </div>
                        </React.Fragment>
                    :
                        <React.Fragment>
                            <div className="row">
                                <div className="col text-center text-success font-weight-bold">
                                    Fin du challenge !
                                </div>
                            </div>
                            
                            <div className="row">
                                <div className="col">
                                    <h3>Voici vos erreurs :</h3>
                                    <table className={[classes.resultTable, "table table-hover text-center"].join(' ')}>
                                        <thead className={classes.headResultTable}>
                                            <tr>
                                                <th>Hiragana</th>
                                                <th>Signification</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {wrongAnswers.map((index) => (
                                            <tr key={index}>
                                                <td>{hiragana[index]}</td>
                                                <td>{romaji[index]}</td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                    <p>Vos résultats sont consultables sur la page du mode d'entraînement.</p>
                                    <div className="text-right">
                                        <Link to='/modes'>
                                            <Button btnType="Continue">Retour aux modes</Button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </React.Fragment>
            }
            
        </React.Fragment>
    );
};

export default HiraganaFurious;