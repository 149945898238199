import React, { useEffect } from 'react';

import LoginForm from './LoginForm/LoginForm';

const Login = (props) => {
    
    useEffect(() => {
        document.title = 'Connectez-vous pour retrouver votre progression | Japan-Teaching';
    }, []);

    return(
        <React.Fragment>
            <div className='container'>
                <div className='row justify-content-md-center'>
                    <div className='col-md-8 col-lg-6'>
                        <LoginForm />
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Login;

