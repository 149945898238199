import React, { useEffect, useState } from 'react';
import moment from 'moment';
import 'moment/locale/fr';
import japanTeachingLogo from '../../../assets/images/japanteaching.png';

import classes from './Mode.module.css';
import Button from '../../../components/UI/Button/Button';
import fire from '../../../config/Fire';
import Spinner from '../../../components/UI/Spinner/Spinner';
import { toast } from 'react-toastify';
import { withRouter, Route } from 'react-router-dom';
import Score from '../../../components/Score/Score';
import HiraganaTraining from '../HiraganaTraining/HiraganaTraining';
import KatakanaTraining from '../KatakanaTraining/KatakanaTraining';
import HiraganaFurious from '../HiraganaFurious/HiraganaFurious';
import KatakanaFurious from '../KatakanaFurious/KatakanaFurious';

const Mode = (props) => {

    // State:loading
    const [loading, setLoading] = useState(false);
    const [mode, setMode] = useState('');
    const [scores, setScores] = useState([]);
    const [gameStarted, setGameStarted] = useState(false);

    useEffect(() => {
        setLoading(true);
        // Fetching firestore data for mode's data
        fire.firestore().collection('modes')
            .where('slug', '==', props.match.params.mode)
            .limit(1)
            .get()
        .then((documents) => {
            // Fetching only the first element (normaly only one document's returned)
            if(documents.docs != '') {
                const data = documents.docs[0].data();
                setMode(data);
            }
            else {
                props.history.replace('/error404');
            }

            const currentUser = fire.auth().currentUser;
            // Fetching scores from this user for this mode
            fire.firestore().collection('scores')
                .where('modeSlug', '==', props.match.params.mode)
                .where('uid', '==', currentUser.uid)
                .orderBy('dateCreation', 'desc')
                .limit(5)
                .get()
            .then((scoresDocuments) => {
                // Does the user has already a score ?
                if(scoresDocuments.docs != '') {

                    let scoresArray = [];
                    scoresDocuments.docs.map(scoreDocument => {
                        scoresArray.push({id: scoreDocument.id, ...scoreDocument.data()});
                    });
                    setScores(scoresArray);
                }
            })
            document.title = documents.docs[0].data().title;
            setLoading(false);
        })
        .catch((error) => {
            toast.error('Une erreur est survenue. Merci de rééssayer plus tard.');
            console.log(error);
            setLoading(false);
        });
        
        
    }, []);

    let scoresDisplay = (
        <Score>Pas encore de score.</Score>
    );
    
    if(scores.length > 0) {
        scoresDisplay = (
            <React.Fragment>
                {scores.map(score => {
                    moment.locale('fr');
                    const date = moment.unix(score.dateCreation.seconds).calendar();
                    return <Score key={score.id} score={score.score} date={date} />;
                })}
            </React.Fragment>
        );
    }

    // Start the game
    const startGameHandler = () => {
        setGameStarted(true);
    };

    return(

        loading ?
            <Spinner />
        :
            gameStarted ?
                <React.Fragment>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-8 mt-5">
                                <div className={[classes.normalModeDisplay, "bg-white p-4 normal-mode-display"].join(' ')}>
                                    <h1>{mode.title}</h1>
                                    <p><b>{mode.instruction}</b></p>
                                    <span className={classes.challengeDiv}>
                                        <Route path='/modes/hiragana-training' component={HiraganaTraining} />
                                        <Route path='/modes/katakana-training' component={KatakanaTraining} />
                                        <Route path='/modes/hiragana-furious' component={HiraganaFurious} />
                                        <Route path='/modes/katakana-furious' component={KatakanaFurious} />
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            :
                <React.Fragment>
                    <div className={[classes.cover, 'mb-0'].join(' ')}>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-3 text-center align-self-center">
                                    <img src={japanTeachingLogo} alt="Icon" className={classes.app} />
                                </div>
                                <div className="col-md-9 align-self-center">
                                    <div className="row">
                                        <h1>{mode.title}</h1>
                                    </div>
                                    <div className="row mt-1">
                                        <Button btnType="Outlined" clicked={startGameHandler}>Commencer</Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="container">
                        {/* Présentation */}
                        <div className="row">
                            <div className="col-md-8">
                                <h1 className="title-header">Présentation du mode</h1>
                                { mode.presentation ? mode.presentation.split('\\n').map((i, key) => {
                                    return <p key={key}>{i}</p>;
                                }) :  null }
                            </div>

                            <div className="col-md-4">
                                <h1 className="title-header text-right title-header-mobile">Derniers scores</h1>
                                {scoresDisplay}
                            </div>
                        </div>
                    </div>
                </React.Fragment>
    );

};

export default withRouter(Mode);