import React from 'react';

import classes from './Score.module.css';

const Score = (props) => {
    
    let content = (
        <React.Fragment>
            {props.score}<br />
            <small className={classes.date}><b>{props.date}</b></small>     
        </React.Fragment>
    );

    if(props.children) {
        content = props.children;
    }

    return(
        <div className={[classes.Score, 'bg-white p-3 mb-3'].join(' ')}>
            {content}
        </div>
    );
    
};

export default Score;