import React from 'react';
import { Link } from 'react-router-dom';

import classes from './Footer.module.css';

const Footer = (props) => {
    const year = new Date().getFullYear();
    
    return(
        <footer className={classes.Footer}>
            <div className="container">
                <ul>
                    <li><Link to="/contact">Contact</Link></li>
                    <li><Link to="/mentions-legales">Mentions Légales</Link></li>
                </ul>
                <span>
                    {year} © Japan Teaching • Une initiative de <a href="https://believemy.com"><b>believemy</b></a>
                </span>
            </div>
        </footer>
    );
};

export default Footer;