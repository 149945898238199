import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';

import classes from './RegisterForm.module.css';
import Input from '../../../components/UI/Input/Input';
import Button from '../../../components/UI/Button/Button';
import fire from '../../../config/Fire';
import Spinner from '../../../components/UI/Spinner/Spinner';
import { toast } from 'react-toastify';

const RegisterForm = (props) => {

    // State: formElements
    const [formElements, setFormElements] = useState({
        email: {
            elementType: 'input',
            elementConfig: {
                type: 'email',
                placeholder: 'Votre adresse email'
            },
            label: 'Adresse email',
            value: '',
            validation: {
                required: true
            },
            errorMessage: 'Veuillez entrer une adresse email.',
            valid: false,
            touched: false
        },
        firstName: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                placeholder: 'Votre prénom'
            },
            label: 'Prénom',
            value: '',
            validation: {
                required: true,
                minLength: 1,
                maxLength: 25
            },
            errorMessage: 'Veuillez entrer votre prénom (25 caractères max).',
            valid: false,
            touched: false
        },
        password: {
            elementType: 'input',
            elementConfig: {
                type: 'password',
                placeholder: 'Choisissez un mot de passe'
            },
            label: 'Mot de passe',
            value: '',
            validation: {
                required: true,
                minLength: 6
            },
            errorMessage: 'Veuillez entrer un mot de passe d\'au moins 6 caractères.',
            valid: false,
            touched: false
        }
    });
    // State: loading
    const [loading, setLoading] = useState(false);
    // State: form validation
    const [formIsValid, setFormIsValid] = useState(false);

    // When form is submitted
    const orderHandler = (event) => {
        event.preventDefault();
        setLoading(true);
        
        // Check form validity
        if(!formIsValid) {
            setLoading(false);
            toast.error('Vérifiez vos informations, certains champs sont mal remplis.');
        }
        else {
            // OK let's create the user
            fire.auth().createUserWithEmailAndPassword(formElements.email.value, formElements.password.value)
                .then((result) => {
                    const db = fire.firestore();
                    db.collection('users').doc(result.user.uid).set({
                        firstName: formElements.firstName.value
                    })
                    .then(() => {
                        setLoading(false);
                        toast.success('Bienvenue sur Japan-Teaching ' + formElements.firstName.value + ' !');
                        props.history.replace('/');
                    });
                })
                .catch((error) => {
                    console.log(error);
                    toast.error('Une erreur est survenue. Rééssayez dans quelques minutes ou contactez-nous.');
                    setLoading(false);
                });
        }
    }

    const checkValidaty = (value, rules) => {
        let isValid = true;

        if(rules.required) {
            isValid = value.trim() !== '' && isValid;
        }

        if(rules.minLength) {
            isValid = value.length >= rules.minLength && isValid;
        }

        if(rules.maxLength) {
            isValid = value.length <= rules.maxLength && isValid;
        }

        return isValid;
    }

    // When input is changed
    const inputChangedHandler = (event, element) => {
        const updatedRegisterForm = {
            ...formElements
        };
        const updatedFormElement = {
            ...updatedRegisterForm[element]
        };
        updatedFormElement.value = event.target.value;
        updatedFormElement.valid = checkValidaty(updatedFormElement.value, updatedFormElement.validation);
        updatedFormElement.touched = true;
        updatedRegisterForm[element] = updatedFormElement;

        setFormElements(updatedRegisterForm);

        let formIsValid = true;
        for(let formElement in updatedRegisterForm) {
            console.log(formIsValid);
            formIsValid = updatedRegisterForm[formElement].valid && formIsValid;
        }
        setFormIsValid(formIsValid);
    }

    // Array of form's elements
    const formElementsArray = [];
    for(let element in formElements) {
        formElementsArray.push({
            id: element,
            config: formElements[element]
        });
    }

    // Form
    let form = <Spinner />;
    
    if(!loading) {
        form = (
            <form onSubmit={(event) => orderHandler(event)}>
                {formElementsArray.map(formElement => (
                    <Input
                        key={formElement.id}
                        elementType={formElement.config.elementType}
                        elementConfig={formElement.config.elementConfig}
                        value={formElement.config.value}
                        invalid={!formElement.config.valid}
                        shouldValidate={formElement.config.validation}
                        errorMessage={formElement.config.errorMessage}
                        touched={formElement.config.touched}
                        label={formElement.config.label}
                        changed={(event) => inputChangedHandler(event, formElement.id)} />
                ))}
                <p>
                    En créant un compte, vous acceptez nos <span>conditions générales d'utilisation</span> et notre <span>politique
                    de confidentialité</span>.
                </p>
                <p>
                    Vous recevrez également des communications pour vous aider à apprendre le japonais
                    de façon simple et concrète. Vous pourrez désactiver nos communications à tout moment.
                </p>
                <div className='mt-3 text-right'>
                    <Button btnType='Continue'>Créer un compte</Button>
                </div>
            </form>
        );
    }

    return(
        <div className={classes.RegisterForm}>
            <h3>Inscrivez-vous gratuitement</h3>
            {form}
        </div>
    );
};

export default withRouter(RegisterForm);