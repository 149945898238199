import React from 'react';

import classes from './NavigationItems.module.css';
import NavigationItem from './NavigationItem/NavigationItem';

const NavigationItems = (props) => {

    let navigationItemsConnected = (
        <React.Fragment>
            <NavigationItem link='/register' exact>Créer un compte</NavigationItem>
            <NavigationItem link='/login' exact>Connexion</NavigationItem>
        </React.Fragment>
    );

    if(props.connected) {
        navigationItemsConnected = (
            <React.Fragment>
                <NavigationItem link='/' exact>Accueil</NavigationItem>
                <NavigationItem link='/parameters'>Profil</NavigationItem>
                <NavigationItem link='/modes'>Modes</NavigationItem>
            </React.Fragment>
        );
    }

    return(
        <ul className={classes.NavigationItems}>
            {navigationItemsConnected}
        </ul>
    );

    // - Inscription / Connexion
    // - Suivi des résultats
    // - Différents modes de jeu
    //     - Challenge : retient les kanas non appris et les donne une fois fini
    //     - Cartes de mémorisation : trouver les deux cartes qui vont ensemble (kana et romanji)
    //     - Chrono : retrouver 50 kanas en le minimum de secondes possible

}

export default NavigationItems;