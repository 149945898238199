import React, { useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './Layout.css';

import Toolbar from '../../components/Navigation/Toolbar/Toolbar';
import Footer from '../../components/Footer/Footer';
import Spinner from '../../components/UI/Spinner/Spinner';
import { withRouter } from 'react-router-dom';

const Layout = (props) => {

    useEffect(() => {
        if(props.history.action === 'POP') {
            return;
        }

        let hash = props.location.hash;
        if(hash) {
            let element = document.querySelector(hash);
            if(element) {
                element.scrollIntoView({block: 'start', behavior: 'smooth'});
            }
        }
        else {
            window.scrollTo(0, 0);
        }
    });

    return(
        <React.Fragment>
            { props.loading ? <Spinner /> : (
                <React.Fragment>
                    <Toolbar connected={props.connected} username={props.firstName} />
                    <div id="content">
                        {props.children}
                    </div>
                    <Footer />
                </React.Fragment>
            )}
            <ToastContainer position={toast.POSITION.TOP_RIGHT} toastClassName="toast-style" />
        </React.Fragment>
    );
}

export default withRouter(Layout);