import React, { useEffect } from 'react';
import ContactForm from './ContactForm/ContactForm';

const Contact = () => {

    useEffect(() => {
        document.title = 'Contactez-nous | Japan-Teaching';
    }, []);

    return(
        <React.Fragment>
            <div className='container'>
                <div className='row justify-content-md-center'>
                    <div className='col-md-8 col-lg-6'>
                        <ContactForm />
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Contact;