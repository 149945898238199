import React, { useEffect } from 'react';

import classes from './LegalMentions.module.css';
import { Link } from 'react-router-dom';

const LegalMentions = () => {

    useEffect(() => {
        document.title = 'Mentions légales | Japan-Teaching';
    }, []);

    return(
        <React.Fragment>
            <div className='container'>
                <div className='row justify-content-md-center'>
                    <div className='col-md-10 col-lg-8'>
                        <div className="p-5">
                            <h2 class="mb-3 text-center">Mentions légales</h2>
                            <p><b>Directeur de la publication</b></p>
                            <p>Prénom et NOM : Louis-Nicolas LEUILLET<br />
                            Prix : TVA non applicable art. 293 b du cgi<br />
                            Adresse email : par formulaire de contact<br />
                            SIREN : 825 379 241<br />
                            Adresse web : <a href="https://louisnicolasleuillet.com">louisnicolasleuillet.com</a></p>

                            <p><b>Hébergement du site japan-teaching.com</b></p>

                            <p>Société : OVH<br />
                            Adresse web : <a href="ovh.com">www.ovh.com</a><br />
                            Adresse Postale : 2 rue kellermann<br />
                            BP 80157<br />
                            59053 ROUBAIX Cedex 1<br />
                            Téléphone : +33 (0)8 203 203 63<br />
                            Adresse électronique (E-Mail) : <a href="mailto:support@ovh.com">support@ovh.com</a></p>

                            <div class={[classes.Documents, 'bg-white p-4 mt-4'].join(' ')}>
                                <h3>Documents annexes</h3>
                                <ul class="my-3">
                                    {/* <li><a href="/cgv">Conditions Générales de Vente</a></li> */}
                                    <li><Link to="/vie-privee">Politique de Confidentialité</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default LegalMentions;