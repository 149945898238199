import React from 'react';

import logo from '../../../assets/images/logo_100.png';

const Logo = (props) => (
    <React.Fragment>
        <img src={logo} style={{height: props.height}} alt="Japan Teaching's logo" />
    </React.Fragment>
);

export default Logo;