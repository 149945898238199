import React from 'react';

import errorImage from '../../assets/images/404.png';

const ErrorMissing = (props) => (
    <div className="container">
        <div className="text-center mt-5 p-4">
            <img src={errorImage} alt="404" height="190px" />
            <h1>Cette page n'est pas sur la carte !</h1>
            <p>Ne sortez pas du chemin, celui sur lequel vous êtes n'existe pas ou n'est pas accessible.</p>
        </div>
    </div>
);

export default ErrorMissing;