import React, { useEffect } from 'react';

import './Welcome.css';
import bgBlossom from '../../assets/images/bg_blossom.jpg';

const Welcome = (props) => {

    useEffect(() => {
        document.title = 'Entraînez-vous gratuitement dans votre apprentissage du Japonais avec Japan Teaching';
    }, []);

    // Jumbotron's background
    const jumbotronBg = {
        backgroundImage: `url(${bgBlossom})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        position: 'relative',
        zIndex: '1'
    }

    // Text shadow property
    const textShadow = {
        textShadow: '0 0 10px #000'
    }

    return(
        <React.Fragment>
            <div className="jumbotron" style={jumbotronBg}>
                <div className="container text-white">
                    <h1 style={textShadow}>Apprenez le Japonais</h1>
                    <p className="lead" style={textShadow}>
                        Le Japonais ? Facile ! Avec notre technologie d'apprentissage,
                        vous apprendrez rapidement à faire vos premiers pas !
                    </p>
                </div>
            </div>

            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-8">
                        <div className="p-1">
                            <h2>100% gratuit</h2>
                            <p>
                                Profitez enfin d'un service 100% gratuit, pour vous entraîner
                                sur vos Hiraganas et vos Katakanas.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-md-8">
                        <div className="p-1">
                            <h2>Utilisations illimitées</h2>
                            <p>
                                Il ne s'agit pas d'un service gratuit que vous ne pouvez utiliser
                                qu'une à trois fois par jour.
                            </p>
                            <p>
                                Sur Japan Teaching, nos modes sont disponibles gratuitement et
                                de façon illimitées.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-md-8">
                        <div className="p-1">
                            <h2>Gardez une trace de vos progrès</h2>
                            <p>
                                Ce n'est pas parce que Japan Teaching est gratuit que nous n'avons
                                pas fait les choses jusqu'au bout ! Tous vos progrès sont sauvegardés
                                en temps réel pour vous aider à suivre votre évolution.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );

}

export default Welcome;