import React from 'react';
import classes from './Input.module.css';

const Input = (props) => {
    let inputElement = null, errorMessage = '';
    const inputClasses = [classes.InputElement];

    if(props.invalid && props.shouldValidate && props.touched) {
        inputClasses.push(classes.Invalid);
        errorMessage = (
            <div className={classes.ErrorMessage}>
                {props.errorMessage}
            </div>
        );
    }

    switch(props.elementType) {
        case('input'):
            inputElement = <input
                className={inputClasses.join(' ')}
                {...props.elementConfig}
                value={props.value}
                onChange={props.changed}
                readOnly={props.readonly} />;
            break;
        case('textarea'):
            inputElement = <textarea
                className={inputClasses.join(' ')}
                {...props.elementConfig}
                value={props.value}
                onChange={props.changed}
                readOnly={props.readonly} />;
            break;
        case('select'):
            inputElement = (
                <select className={inputClasses.join(' ')} value={props.value} onChange={props.changed} readOnly={props.readonly}>
                    {props.elementConfig.options.map(option => {
                        return <option key={option.value} value={option.value}>{option.displayValue}</option>;
                    })}
                </select>
            );
            break;
        default:
            inputElement = <input className={classes.InputElement} {...props.elementConfig} value={props.value} onChange={props.changed} readOnly={props.readonly} />;
    }

    return(
        <div className={classes.Input}>
            <label className={classes.Label}>{props.label}</label>
            {inputElement}
            {errorMessage}
        </div>
    );
};

export default Input;