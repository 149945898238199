import React, { useEffect } from 'react';

const Privacy = () => {

    useEffect(() => {
        document.title = 'Politique de confidentialité | Japan-Teaching';
    }, []);

    return(
        <React.Fragment>
            <div className='container'>
                <div className='row justify-content-md-center'>
                    <div className='col-md-10 col-lg-8'>
                        <div className="p-5">
                            <h2 class="mb-3 text-center">Politique de confidentalité</h2>
                            <h3><u>D&eacute;finitions</u>&nbsp;:</h3>

                            <p><strong>L&#39;&Eacute;diteur</strong>&nbsp;: La personne, physique ou morale, qui &eacute;dite les services de communication au public en ligne.<br />
                            <strong>Le Site</strong>&nbsp;: L&#39;ensemble des sites, pages Internet et services en ligne propos&eacute;s par l&#39;&Eacute;diteur.<br />
                            <strong>L&#39;Utilisateur</strong>&nbsp;: La personne utilisant le Site et les services.</p>

                            <h3>Nature des donn&eacute;es collect&eacute;es</h3>

                            <p><strong>Dans le cadre de l&#39;utilisation des Sites, l&#39;&Eacute;diteur est susceptible de collecter les cat&eacute;gories de donn&eacute;es suivantes concernant ses Utilisateurs :</strong></p>

                            <p>Donn&eacute;es d&#39;&eacute;tat-civil, d&#39;identit&eacute;, d&#39;identification...</p>

                            <p>Donn&eacute;es relatives &agrave; la vie professionnelle (CV, scolarit&eacute;, formation professionnelle, distinctions...)</p>

                            <p>Donn&eacute;es de connexion (adresses IP, journaux d&#39;&eacute;v&eacute;nements...)</p>

                            <p>Donn&eacute;es de localisation (d&eacute;placements, donn&eacute;es GPS, GSM...)</p>

                            <h3>Communication des donn&eacute;es personnelles &agrave; des tiers</h3>

                            <p><strong>Pas de communication &agrave; des tiers</strong><br />
                            Vos donn&eacute;es ne font l&#39;objet d&#39;aucune communication &agrave; des tiers. Vous &ecirc;tes toutefois inform&eacute;s qu&#39;elles pourront &ecirc;tre divulgu&eacute;es en application d&#39;une loi, d&#39;un r&egrave;glement ou en vertu d&#39;une d&eacute;cision d&#39;une autorit&eacute; r&eacute;glementaire ou judiciaire comp&eacute;tente.</p>

                            <h3>Information pr&eacute;alable pour la communication des donn&eacute;es personnelles &agrave; des tiers en cas de fusion / absorption</h3>

                            <p><strong>Information pr&eacute;alable et possibilit&eacute; d&rsquo;opt-out avant et apr&egrave;s la fusion / acquisition</strong><br />
                            Dans le cas o&ugrave; nous prendrions part &agrave; une op&eacute;ration de fusion, d&rsquo;acquisition ou &agrave; toute autre forme de cession d&rsquo;actifs, nous nous engageons &agrave; garantir la confidentialit&eacute; de vos donn&eacute;es personnelles et &agrave; vous informer avant que celles-ci ne soient transf&eacute;r&eacute;es ou soumises &agrave; de nouvelles r&egrave;gles de confidentialit&eacute;.</p>

                            <p>&nbsp;</p>

                            <h3>Finalit&eacute; de la r&eacute;utilisation des donn&eacute;es personnelles collect&eacute;es</h3>

                            <p><strong>Effectuer les op&eacute;rations relatives &agrave; la gestion des clients concernant</strong></p>

                            <ul>
                                <li>les contrats ; les commandes ; les livraisons ; les factures ; la comptabilit&eacute; et en particulier la gestion des comptes clients</li>
                                <li>un programme de fid&eacute;lit&eacute; au sein d&#39;une entit&eacute; ou plusieurs entit&eacute;s juridiques ;</li>
                                <li>le suivi de la relation client tel que la r&eacute;alisation d&#39;enqu&ecirc;tes de satisfaction, la gestion des r&eacute;clamations et du service apr&egrave;s-vente</li>
                                <li>la s&eacute;lection de clients pour r&eacute;aliser des &eacute;tudes, sondages et tests produits (sauf consentement des personnes concern&eacute;es recueilli dans les conditions pr&eacute;vues &agrave; l&rsquo;article 6, ces op&eacute;rations ne doivent pas conduire &agrave; l&#39;&eacute;tablissement de profils susceptibles de faire appara&icirc;tre des donn&eacute;es sensibles - origines raciales ou ethniques, opinions philosophiques, politiques, syndicales, religieuses, vie sexuelle ou sant&eacute; des personnes)</li>
                            </ul>

                            <p>&nbsp;</p>

                            <p><strong>Effectuer des op&eacute;rations relatives &agrave; la prospection</strong></p>

                            <ul>
                                <li>la gestion d&#39;op&eacute;rations techniques de prospection (ce qui inclut notamment les op&eacute;rations techniques comme la normalisation, l&#39;enrichissement et la d&eacute;duplication)</li>
                                <li>la s&eacute;lection de personnes pour r&eacute;aliser des actions de fid&eacute;lisation, de prospection, de sondage, de test produit et de promotion. Sauf consentement des personnes concern&eacute;es recueilli dans les conditions pr&eacute;vues &agrave; l&#39;article 6, ces op&eacute;rations ne doivent pas conduire &agrave; l&#39;&eacute;tablissement de profils susceptibles de faire appara&icirc;tre des donn&eacute;es sensibles (origines raciales ou ethniques, opinions philosophiques, politiques, syndicales, religieuses, vie sexuelle ou sant&eacute; des personnes)</li>
                                <li>la r&eacute;alisation d&#39;op&eacute;rations de sollicitations</li>
                            </ul>

                            <p>&nbsp;</p>

                            <p><strong>L&#39;&eacute;laboration de statistiques commerciales</strong></p>

                            <p><strong>L&#39;organisation de jeux concours, de loteries ou de toute op&eacute;ration promotionnelle &agrave; l&#39;exclusion des jeux d&#39;argent et de hasard en ligne soumis &agrave; l&#39;agr&eacute;ment de l&#39;Autorit&eacute; de R&eacute;gulation des Jeux en Ligne</strong></p>

                            <p><strong>La gestion des demandes de droit d&#39;acc&egrave;s, de rectification et d&#39;opposition</strong></p>

                            <p><strong>La gestion des impay&eacute;s et du contentieux, &agrave; condition qu&#39;elle ne porte pas sur des infractions et / ou qu&#39;elle n&#39;entra&icirc;ne pas une exclusion de la personne du b&eacute;n&eacute;fice d&#39;un droit, d&#39;une prestation ou d&#39;un contrat</strong></p>

                            <p><strong>La gestion des avis des personnes sur des produits, services ou contenus</strong></p>

                            <h3>Agr&eacute;gation des donn&eacute;es</h3>

                            <p><strong>Agr&eacute;gation avec des donn&eacute;es non personnelles</strong><br />
                            Nous pouvons publier, divulguer et utiliser les informations agr&eacute;g&eacute;es (informations relatives &agrave; tous nos Utilisateurs ou &agrave; des groupes ou cat&eacute;gories sp&eacute;cifiques d&#39;Utilisateurs que nous combinons de mani&egrave;re &agrave; ce qu&#39;un Utilisateur individuel ne puisse plus &ecirc;tre identifi&eacute; ou mentionn&eacute;) et les informations non personnelles &agrave; des fins d&#39;analyse du secteur et du march&eacute;, de profilage d&eacute;mographique, &agrave; des fins promotionnelles et publicitaires et &agrave; d&#39;autres fins commerciales.</p>

                            <p><strong>Agr&eacute;gation avec des donn&eacute;es personnelles disponibles sur les comptes sociaux de l&#39;Utilisateur</strong><br />
                            Si vous connectez votre compte &agrave; un compte d&rsquo;un autre service afin de faire des envois crois&eacute;s, ledit service pourra nous communiquer vos informations de profil, de connexion, ainsi que toute autre information dont vous avez autoris&eacute; la divulgation. Nous pouvons agr&eacute;ger les informations relatives &agrave; tous nos autres Utilisateurs, groupes, comptes, aux donn&eacute;es personnelles disponibles sur l&rsquo;Utilisateur.</p>

                            <h3>Collecte des donn&eacute;es d&#39;identit&eacute;</h3>

                            <p><strong>Utilisation d&rsquo;un pseudonyme</strong><br />
                            L&rsquo;utilisation du Site n&eacute;cessite une inscription sans identification pr&eacute;alable. Elle peut s&rsquo;effectuer sans que vous ne communiquiez de donn&eacute;es nominatives vous concernant (nom, pr&eacute;nom, adresse, etc). Vous pouvez utiliser le pseudonyme de votre choix.</p>

                            <h3>Collecte des donn&eacute;es d&#39;identification</h3>

                            <p><strong>Utilisation de l&#39;identifiant de l&rsquo;utilisateur uniquement pour l&rsquo;acc&egrave;s aux services</strong><br />
                            Nous utilisons vos identifiants &eacute;lectroniques seulement pour et pendant l&#39;ex&eacute;cution du contrat.</p>

                            <h3>Collecte des donn&eacute;es du terminal</h3>

                            <p><strong>Collecte des donn&eacute;es de profilage et des donn&eacute;es techniques &agrave; des fins de fourniture du service</strong><br />
                            Certaines des donn&eacute;es techniques de votre appareil sont collect&eacute;es automatiquement par le Site. Ces informations incluent notamment votre adresse IP, fournisseur d&#39;acc&egrave;s &agrave; Internet, configuration mat&eacute;rielle, configuration logicielle, type et langue du navigateur... La collecte de ces donn&eacute;es est n&eacute;cessaire &agrave; la fourniture des services.</p>

                            <p><strong>Collecte des donn&eacute;es techniques &agrave; des fins publicitaires, commerciales et statistiques</strong><br />
                            Les donn&eacute;es techniques de votre appareil sont automatiquement collect&eacute;es et enregistr&eacute;es par le Site, &agrave; des fins publicitaires, commerciales et statistiques. Ces informations nous aident &agrave; personnaliser et &agrave; am&eacute;liorer continuellement votre exp&eacute;rience sur notre Site. Nous ne collectons ni ne conservons aucune donn&eacute;e nominative (nom, pr&eacute;nom, adresse...) &eacute;ventuellement attach&eacute;e &agrave; une donn&eacute;e technique. Les donn&eacute;es collect&eacute;es sont susceptibles d&rsquo;&ecirc;tre revendues &agrave; des tiers.</p>

                            <h3>Cookies</h3>

                            <p><strong>Dur&eacute;e de conservation des cookies</strong><br />
                            Conform&eacute;ment aux recommandations de la CNIL, la dur&eacute;e maximale de conservation des cookies est de 13 mois au maximum apr&egrave;s leur premier d&eacute;p&ocirc;t dans le terminal de l&#39;Utilisateur, tout comme la dur&eacute;e de la validit&eacute; du consentement de l&rsquo;Utilisateur &agrave; l&rsquo;utilisation de ces cookies. La dur&eacute;e de vie des cookies n&rsquo;est pas prolong&eacute;e &agrave; chaque visite. Le consentement de l&rsquo;Utilisateur devra donc &ecirc;tre renouvel&eacute; &agrave; l&#39;issue de ce d&eacute;lai.</p>

                            <p><strong>Finalit&eacute; cookies</strong><br />
                            Les cookies peuvent &ecirc;tre utilis&eacute;s pour des fins statistiques notamment pour optimiser les services rendus &agrave; l&#39;Utilisateur, &agrave; partir du traitement des informations concernant la fr&eacute;quence d&#39;acc&egrave;s, la personnalisation des pages ainsi que les op&eacute;rations r&eacute;alis&eacute;es et les informations consult&eacute;es.<br />
                            Vous &ecirc;tes inform&eacute; que l&#39;&Eacute;diteur est susceptible de d&eacute;poser des cookies sur votre terminal. Le cookie enregistre des informations relatives &agrave; la navigation sur le service (les pages que vous avez consult&eacute;es, la date et l&#39;heure de la consultation...) que nous pourrons lire lors de vos visites ult&eacute;rieures.</p>

                            <p><strong>Droit de l&#39;Utilisateur de refuser les cookies, la d&eacute;sactivation entra&icirc;nant un fonctionnement d&eacute;grad&eacute; du service</strong><br />
                            Vous reconnaissez avoir &eacute;t&eacute; inform&eacute; que l&#39;&Eacute;diteur peut avoir recours &agrave; des cookies, et l&#39;y autorisez. Si vous ne souhaitez pas que des cookies soient utilis&eacute;s sur votre terminal, la plupart des navigateurs vous permettent de d&eacute;sactiver les cookies en passant par les options de r&eacute;glage. Toutefois, vous &ecirc;tes inform&eacute; que certains services sont susceptibles de ne plus fonctionner correctement.</p>

                            <p><strong>Association possible des cookies avec des donn&eacute;es personnelles pour permettre le fonctionnement du service</strong><br />
                            L&#39;&Eacute;diteur peut &ecirc;tre amen&eacute;e &agrave; recueillir des informations de navigation via l&#39;utilisation de cookies.</p>

                            <h3>Conservation des donn&eacute;es techniques</h3>

                            <p><strong>Dur&eacute;e de conservation des donn&eacute;es techniques</strong><br />
                            Les donn&eacute;es techniques sont conserv&eacute;es pour la dur&eacute;e strictement n&eacute;cessaire &agrave; la r&eacute;alisation des finalit&eacute;s vis&eacute;es ci-avant.</p>

                            <h3>D&eacute;lai de conservation des donn&eacute;es personnelles et d&#39;anonymisation</h3>

                            <p><strong>Conservation des donn&eacute;es pendant la dur&eacute;e de la relation contractuelle</strong><br />
                            Conform&eacute;ment &agrave; l&#39;article 6-5&deg; de la loi n&deg;78-17 du 6 janvier 1978 relative &agrave; l&#39;informatique, aux fichiers et aux libert&eacute;s, les donn&eacute;es &agrave; caract&egrave;re personnel faisant l&#39;objet d&#39;un traitement ne sont pas conserv&eacute;es au-del&agrave; du temps n&eacute;cessaire &agrave; l&#39;ex&eacute;cution des obligations d&eacute;finies lors de la conclusion du contrat ou de la dur&eacute;e pr&eacute;d&eacute;finie de la relation contractuelle.</p>

                            <p><strong>Conservation des donn&eacute;es anonymis&eacute;es au del&agrave; de la relation contractuelle / apr&egrave;s la suppression du compte</strong><br />
                            Nous conservons les donn&eacute;es personnelles pour la dur&eacute;e strictement n&eacute;cessaire &agrave; la r&eacute;alisation des finalit&eacute;s d&eacute;crites dans les pr&eacute;sentes CGU. Au-del&agrave; de cette dur&eacute;e, elles seront anonymis&eacute;es et conserv&eacute;es &agrave; des fins exclusivement statistiques et ne donneront lieu &agrave; aucune exploitation, de quelque nature que ce soit.</p>

                            <p><strong>Suppression des donn&eacute;es apr&egrave;s suppression du compte</strong><br />
                            Des moyens de purge de donn&eacute;es sont mis en place afin d&#39;en pr&eacute;voir la suppression effective d&egrave;s lors que la dur&eacute;e de conservation ou d&#39;archivage n&eacute;cessaire &agrave; l&#39;accomplissement des finalit&eacute;s d&eacute;termin&eacute;es ou impos&eacute;es est atteinte. Conform&eacute;ment &agrave; la loi n&deg;78-17 du 6 janvier 1978 relative &agrave; l&#39;informatique, aux fichiers et aux libert&eacute;s, vous disposez par ailleurs d&#39;un droit de suppression sur vos donn&eacute;es que vous pouvez exercer &agrave; tout moment en prenant contact avec l&#39;&Eacute;diteur.</p>

                            <p><strong>Suppression des donn&eacute;es apr&egrave;s 3 ans d&#39;inactivit&eacute;</strong><br />
                            Pour des raisons de s&eacute;curit&eacute;, si vous ne vous &ecirc;tes pas authentifi&eacute; sur le Site pendant une p&eacute;riode de trois ans, vous recevrez un e-mail vous invitant &agrave; vous connecter dans les plus brefs d&eacute;lais, sans quoi vos donn&eacute;es seront supprim&eacute;es de nos bases de donn&eacute;es.</p>

                            <h3>Suppression du compte</h3>

                            <p><strong>Suppression du compte &agrave; la demande</strong><br />
                            L&#39;Utilisateur a la possibilit&eacute; de supprimer son Compte &agrave; tout moment, par simple demande &agrave; l&#39;&Eacute;diteur OU par le menu de suppression de Compte pr&eacute;sent dans les param&egrave;tres du Compte le cas &eacute;ch&eacute;ant.</p>

                            <p><strong>Suppression du compte en cas de violation des CGU</strong><br />
                            En cas de violation d&#39;une ou de plusieurs dispositions des CGU ou de tout autre document incorpor&eacute; aux pr&eacute;sentes par r&eacute;f&eacute;rence, l&#39;&Eacute;diteur se r&eacute;serve le droit de mettre fin ou restreindre sans aucun avertissement pr&eacute;alable et &agrave; sa seule discr&eacute;tion, votre usage et acc&egrave;s aux services, &agrave; votre compte et &agrave; tous les Sites.</p>

                            <h3>Indications en cas de faille de s&eacute;curit&eacute; d&eacute;cel&eacute;e par l&#39;&Eacute;diteur</h3>

                            <p><strong>Information de l&#39;Utilisateur en cas de faille de s&eacute;curit&eacute;</strong><br />
                            Nous nous engageons &agrave; mettre en oeuvre toutes les mesures techniques et organisationnelles appropri&eacute;es afin de garantir un niveau de s&eacute;curit&eacute; adapt&eacute; au regard des risques d&#39;acc&egrave;s accidentels, non autoris&eacute;s ou ill&eacute;gaux, de divulgation, d&#39;alt&eacute;ration, de perte ou encore de destruction des donn&eacute;es personnelles vous concernant. Dans l&#39;&eacute;ventualit&eacute; o&ugrave; nous prendrions connaissance d&#39;un acc&egrave;s ill&eacute;gal aux donn&eacute;es personnelles vous concernant stock&eacute;es sur nos serveurs ou ceux de nos prestataires, ou d&#39;un acc&egrave;s non autoris&eacute; ayant pour cons&eacute;quence la r&eacute;alisation des risques identifi&eacute;s ci-dessus, nous nous engageons &agrave; :</p>

                            <ul>
                                <li>Vous notifier l&#39;incident dans les plus brefs d&eacute;lais ;</li>
                                <li>Examiner les causes de l&#39;incident et vous en informer ;</li>
                                <li>Prendre les mesures n&eacute;cessaires dans la limite du raisonnable afin d&#39;amoindrir les effets n&eacute;gatifs et pr&eacute;judices pouvant r&eacute;sulter dudit incident</li>
                            </ul>

                            <p>&nbsp;</p>

                            <p><strong>Limitation de la responsabilit&eacute;</strong><br />
                            En aucun cas les engagements d&eacute;finis au point ci-dessus relatifs &agrave; la notification en cas de faille de s&eacute;curit&eacute; ne peuvent &ecirc;tre assimil&eacute;s &agrave; une quelconque reconnaissance de faute ou de responsabilit&eacute; quant &agrave; la survenance de l&#39;incident en question.</p>

                            <h3>Transfert des donn&eacute;es personnelles &agrave; l&#39;&eacute;tranger</h3>

                            <p><strong>Pas de transfert en dehors de l&#39;Union europ&eacute;enne</strong><br />
                            L&#39;&Eacute;diteur s&#39;engage &agrave; ne pas transf&eacute;rer les donn&eacute;es personnelles de ses Utilisateurs en dehors de l&#39;Union europ&eacute;enne.</p>

                            <h3>Modification des CGU et de la politique de confidentialit&eacute;</h3>

                            <p><strong>En cas de modification des pr&eacute;sentes CGU, engagement de ne pas baisser le niveau de confidentialit&eacute; de mani&egrave;re substantielle sans l&#39;information pr&eacute;alable des personnes concern&eacute;es</strong><br />
                            Nous nous engageons &agrave; vous informer en cas de modification substantielle des pr&eacute;sentes CGU, et &agrave; ne pas baisser le niveau de confidentialit&eacute; de vos donn&eacute;es de mani&egrave;re substantielle sans vous en informer et obtenir votre consentement.</p>

                            <h3>Droit applicable et modalit&eacute;s de recours</h3>

                            <p><strong>Application du droit fran&ccedil;ais (l&eacute;gislation CNIL) et comp&eacute;tence des tribunaux</strong><br />
                            Les pr&eacute;sentes CGU et votre utilisation du Site sont r&eacute;gies et interpr&eacute;t&eacute;es conform&eacute;ment aux lois de France, et notamment &agrave; la Loi n&deg; 78-17 du 6 janvier 1978 relative &agrave; l&#39;informatique, aux fichiers et aux libert&eacute;s. Le choix de la loi applicable ne porte pas atteinte &agrave; vos droits en tant que consommateur conform&eacute;ment &agrave; la loi applicable de votre lieu de r&eacute;sidence. Si vous &ecirc;tes un consommateur, vous et nous acceptons de se soumettre &agrave; la comp&eacute;tence non-exclusive des juridictions fran&ccedil;aises, ce qui signifie que vous pouvez engager une action relative aux pr&eacute;sentes CGU en France ou dans le pays de l&#39;UE dans lequel vous vivez. Si vous &ecirc;tes un professionnel, toutes les actions &agrave; notre encontre doivent &ecirc;tre engag&eacute;es devant une juridiction en France.<br />
                            <br />
                            En cas de litige, les parties chercheront une solution amiable avant toute action judiciaire. En cas d&#39;&eacute;chec de ces tentatives, toutes contestations &agrave; la validit&eacute;, l&#39;interpr&eacute;tation et / ou l&#39;ex&eacute;cution des pr&eacute;sentes CGU devront &ecirc;tre port&eacute;es m&ecirc;me en cas de pluralit&eacute; des d&eacute;fendeurs ou d&#39;appel en garantie, devant les tribunaux fran&ccedil;ais.</p>

                            <h3>Portabilit&eacute; des donn&eacute;es</h3>

                            <p><strong>Portabilit&eacute; des donn&eacute;es</strong><br />
                            L&#39;&Eacute;diteur s&#39;engage &agrave; vous offrir la possibilit&eacute; de vous faire restituer l&#39;ensemble des donn&eacute;es vous concernant sur simple demande. L&#39;Utilisateur se voit ainsi garantir une meilleure ma&icirc;trise de ses donn&eacute;es, et garde la possibilit&eacute; de les r&eacute;utiliser. Ces donn&eacute;es devront &ecirc;tre fournies dans un format ouvert et ais&eacute;ment r&eacute;utilisable.</p>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Privacy;