import React from 'react';

import classes from './ParametersNav.module.css';
import { NavLink, withRouter } from 'react-router-dom';
import fire from '../../../config/Fire';
import { toast } from 'react-toastify';

const ParametersNav = (props) => {

    const logout = () => {
        fire.auth().signOut();
        toast.success('Vous êtes maintenant déconnecté.');
        props.history.replace('/');
    };

    return(
        <div className={classes.ParametersNav}>
            <ul>
                <NavLink to='/parameters' exact activeClassName={classes.active}><li>Profil</li></NavLink>
                <NavLink to='/parameters/confidentiality' activeClassName={classes.active}><li>Confidentialité</li></NavLink>
                {/* <NavLink to='/parameters/settings' activeClassName={classes.active}><li>Réglages</li></NavLink> */}
                <li className={classes.w500} onClick={logout}>Déconnexion</li>
            </ul>
        </div>
    );
};

export default withRouter(ParametersNav);