import React from 'react';

import ParametersNav from '../../../components/Navigation/ParametersNav/ParametersNav';
import ProfilTab from './ProfilTab/ProfilTab';
import { Route } from 'react-router-dom';
import SettingsTab from './SettingsTab/SettingsTab';
import ConfidentialityTab from './ConfidentialityTab/ConfidentialityTab';

const Parameters = (props) => {
    
    return(
        <React.Fragment>
            <div className="container">

                <div className="row">
                    <div className="col-12">
                        <h1 className="title-header">Paramètres et réglages</h1>
                    </div>
                </div>

                <div className="row mt-3">
                    <div className="col-lg-4">
                        <ParametersNav />
                    </div>
                    <div className="col-lg-8">
                        <div className="bg-white p-4">
                            <Route path='/parameters' render={() => <ProfilTab user={props.user} updateUserState={props.updateUserState} />} exact />
                            <Route path='/parameters/confidentiality' render={() => <ConfidentialityTab user={props.user} updateUserState={props.updateUserState} />} exact />
                            <Route path='/parameters/settings' render={() => <SettingsTab user={props.user} />} exact />
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Parameters;