import React, { useState, useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';
import './App.css';

import Layout from './hoc/Layout/Layout';
import Welcome from './containers/Welcome/Welcome';
import Dashboard from './containers/Dashboard/Dashboard';
import Register from './containers/Register/Register';
import Login from './containers/Login/Login';
import Contact from './containers/Legals/Contact/Contact';
import fire from './config/Fire';
import Error404 from './components/Error/ErrorMissing';
import PrivateRoute from './hoc/PrivateRoute/PrivateRoute';
import LegalMentions from './containers/Legals/LegalMentions/LegalMentions';
import Privacy from './containers/Legals/Privacy/Privacy';
import Parameters from './containers/Profil/Parameters/Parameters';
import PrivateRouteRender from './hoc/PrivateRouteRender/PrivateRouteRender';
import Modes from './containers/Modes/Modes';
import Mode from './containers/Modes/Mode/Mode';

const App = () => {

  const [connected, setConnected] = useState(false);
  const [user, setUser] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
      authListener();
  }, []);

  const authListener = () => {
      // Fetching firebase auth
      fire.auth().onAuthStateChanged((result) => {
          if(result) {
              setConnected(true);
              
              // Fetching firestore data
              fire.firestore().collection('users').doc(result.uid).get()
                .then((document) => {
                  setUser({id: result.uid, email: result.email, ...document.data()});
                  
                });
              setLoading(false);
          }
          else {
              setConnected(false);
              setLoading(false);
          }
      });
  }

  const updateUserState = (newUserState) => {
    setUser(newUserState);
  }
  
  return (
    <div className="App">
      <Layout loading={loading} firstName={user.firstName} connected={connected}>
        <Switch>
          <Route path='/' render={() => connected ? <Dashboard user={user} /> : <Welcome />} exact />
          <PrivateRoute path='/register' component={Register} exact forConnected={false} isConnected={connected} />
          <PrivateRoute path='/login' component={Login} exact forConnected={false} isConnected={connected} />

          <Route path='/contact' component={Contact} exact />
          <Route path='/mentions-legales' component={LegalMentions} exact />
          <Route path='/vie-privee' component={Privacy} exact />

          <PrivateRoute path='/modes' component={Modes} exact forConnected={true} isConnected={connected} />
          <PrivateRouteRender path='/modes/:mode' render={() => <Mode user={user} />} forConnected={true} isConnected={connected} />

          <PrivateRouteRender path='/parameters' render={() => <Parameters user={user} updateUserState={updateUserState} />} exact forConnected={true} isConnected={connected} />
          <PrivateRouteRender path='/parameters/:section' render={() => <Parameters user={user} updateUserState={updateUserState} />} forConnected={true} isConnected={connected} />
          {/* <PrivateRouteRender path='/settings' render={() => <Parameters user={user} updateUserState={updateUserState} />} exact forConnected={true} isConnected={connected} /> */}

          {/* INCLUDED ROUTES : Parameters, Mode */}
          <Route component={Error404} />
        </Switch>
      </Layout>
    </div>
  );
}

export default App;
