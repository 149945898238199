import React, { useEffect } from 'react';

import './Dashboard.css';
import bgBlossom from '../../assets/images/bg_blossom.jpg';
import Button from '../../components/UI/Button/Button';
import { Link } from 'react-router-dom';

const Dashboard = (props) => {

    useEffect(() => {
        document.title = 'Tableau de bord | Japan-Teaching';
    }, []);

    // Jumbotron's background
    const jumbotronBg = {
        backgroundImage: `url(${bgBlossom})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        position: 'relative',
        zIndex: '1'
    }

    // Text shadow property
    const textShadow = {
        textShadow: '0 0 10px #000'
    }

    return(
        <React.Fragment>
            <div className="jumbotron mb-0" style={jumbotronBg}>
                <div className="container text-white">
                    <h1 className="jumbotron-title" style={textShadow}>こんにちは、<span>{props.user.firstName}。</span></h1>
                    <p className="lead" style={textShadow}>
                        Comment allez-vous vous entraîner aujourd'hui ?
                    </p>
                </div>
            </div>

            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <h1 className="title-header">Commencez votre entraînement</h1>
                    </div>
                </div>

                <div className="row">
                    <div className="col-xl-4 col-lg-5 col-md-6">
                        <div className="bg-white normal-div-display p-4 my-3">
                            <div className="col p-0 pt-2">
                                <h3 className="lesson-title">Premiers pas avec les Hiraganas.</h3>
                            </div>

                            <div className="row mt-4">
                                <div className="col align-self-center">
                                    <i className="far fa-clock"></i> 3min.
                                </div>
                                <div className="col text-right">
                                    <Link to='/modes/hiragana-training'>
                                        <Button btnType='Shy'>Commencer</Button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-4 col-lg-5 col-md-6">
                        <div className="bg-white normal-div-display p-4 my-3">
                            <div className="col p-0 pt-2">
                                <h3 className="lesson-title">Premiers pas avec les Katakanas.</h3>
                            </div>

                            <div className="row mt-4">
                                <div className="col align-self-center">
                                    <i className="far fa-clock"></i> 3min.
                                </div>
                                <div className="col text-right">
                                    <Link to='/modes/katakana-training'>
                                        <Button btnType='Shy'>Commencer</Button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );

}

export default Dashboard;