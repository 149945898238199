import firebase from 'firebase/app';
require('firebase/auth');
require('firebase/firestore');

const config = {
    apiKey: "AIzaSyBp-1fJiCcUrXf6KEGXEery-TZBGEEK9HY",
    authDomain: "japan-teaching.firebaseapp.com",
    databaseURL: "https://japan-teaching.firebaseio.com",
    projectId: "japan-teaching",
    storageBucket: "japan-teaching.appspot.com",
    messagingSenderId: "885930301862",
    appId: "1:885930301862:web:fdfce07125f75b862bd767",
    measurementId: "G-NBJ47SCLZ6"
};

const fire = firebase.initializeApp(config);
export default fire;