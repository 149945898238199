import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';

import classes from './LoginForm.module.css';
import Input from '../../../components/UI/Input/Input';
import Button from '../../../components/UI/Button/Button';
import fire from '../../../config/Fire';
import Spinner from '../../../components/UI/Spinner/Spinner';

const RegisterForm = (props) => {

    // State : formElements
    const [formElements, setFormElements] = useState({
        email: {
            elementType: 'input',
            elementConfig: {
                type: 'email',
                placeholder: 'Votre adresse email'
            },
            label: 'Adresse email',
            value: '',
            validation: {
                required: true
            },
            errorMessage: 'Veuillez entrer votre adresse email.',
            valid: false,
            touched: false
        },
        password: {
            elementType: 'input',
            elementConfig: {
                type: 'password',
                placeholder: 'Votre mot de passe'
            },
            label: 'Mot de passe',
            value: '',
            validation: {
                required: true
            },
            errorMessage: 'Veuillez entrer votre mot de passe.',
            valid: false,
            touched: false
        }
    });
    // State: loading
    const [loading, setLoading] = useState(false);

    // When form is submitted
    const orderHandler = (event) => {
        event.preventDefault();
        setLoading(true);
        fire.auth().signInWithEmailAndPassword(formElements.email.value, formElements.password.value)
            .then((user) => {
                setLoading(false);
                toast.success('Vous voici connecté avec succès !');
                props.history.replace('/');
            })
            .catch((error) => {
                console.log(error);
                toast.error('Une erreur est survenue. Vérifiez vos informations ou contactez-nous.');
                setLoading(false);
            });
    }

    const checkValidaty = (value, rules) => {
        let isValid = true;

        if(rules.required) {
            isValid = value.trim() !== '' && isValid;
        }

        if(rules.minLength) {
            isValid = value.length >= rules.minLength && isValid;
        }

        if(rules.maxLength) {
            isValid = value.length <= rules.maxLength && isValid;
        }

        return isValid;
    }

    // When input is changed
    const inputChangedHandler = (event, element) => {
        const updatedRegisterForm = {
            ...formElements
        };
        const updatedFormElement = {
            ...updatedRegisterForm[element]
        };
        updatedFormElement.value = event.target.value;
        updatedFormElement.valid = checkValidaty(updatedFormElement.value, updatedFormElement.validation);
        updatedFormElement.touched = true;
        updatedRegisterForm[element] = updatedFormElement;

        setFormElements(updatedRegisterForm);
    }

    // Array of form's elements
    const formElementsArray = [];
    for(let element in formElements) {
        formElementsArray.push({
            id: element,
            config: formElements[element]
        });
    }

    // Form
    let form = <Spinner />;

    if(!loading) {
        form = (
            <form onSubmit={(event) => orderHandler(event)}>
                {formElementsArray.map(formElement => (
                    <Input
                        key={formElement.id}
                        elementType={formElement.config.elementType}
                        elementConfig={formElement.config.elementConfig}
                        value={formElement.config.value}
                        invalid={!formElement.config.valid}
                        shouldValidate={formElement.config.validation}
                        errorMessage={formElement.config.errorMessage}
                        touched={formElement.config.touched}
                        label={formElement.config.label}
                        changed={(event) => inputChangedHandler(event, formElement.id)} />
                ))}
                <div className='mt-3 text-right'>
                    <Button btnType='Continue'>Connexion</Button>
                </div>
            </form>
        );
    }

    return(
        <div className={classes.LoginForm}>
            <h3>Connectez-vous à votre compte</h3>
            {form}
        </div>
    );
};

export default withRouter(RegisterForm);