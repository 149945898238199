import React, { useState, useEffect } from 'react';

import fire from '../../../../config/Fire';
import Spinner from '../../../../components/UI/Spinner/Spinner';
import Button from '../../../../components/UI/Button/Button';
import Input from '../../../../components/UI/Input/Input';
import { toast } from 'react-toastify';

const ProfilTab = (props) => {

    useEffect(() => {
        document.title = 'Paramètres et réglages | Japan-Teaching';
    }, []);

    // State: form
    const [formElements, setFormElements] = useState({
        email: {
            elementType: 'input',
            elementConfig: {
                type: 'email',
                placeholder: 'Votre adresse email'
            },
            label: 'Adresse email',
            value: props.user.email,
            validation: {
                required: false
            },
            errorMessage: 'Veuillez entrer une adresse email.',
            valid: true,
            touched: false,
            readonly: true
        },
        firstName: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                placeholder: 'Votre prénom'
            },
            label: 'Prénom',
            value: props.user.firstName,
            validation: {
                required: true,
                minLength: 1,
                maxLength: 25
            },
            errorMessage: 'Veuillez entrer votre prénom (25 caractères max).',
            valid: false,
            touched: false,
            readonly: false
        }
    });
    // State: loading
    const [loading, setLoading] = useState(false);
    // State: form validation
    const [formIsValid, setFormIsValid] = useState(false);

    const checkValidaty = (value, rules) => {
        let isValid = true;

        if(rules.required) {
            isValid = value.trim() !== '' && isValid;
        }

        if(rules.minLength) {
            isValid = value.length >= rules.minLength && isValid;
        }

        if(rules.maxLength) {
            isValid = value.length <= rules.maxLength && isValid;
        }

        return isValid;
    }

    // When input is changed
    const inputChangedHandler = (event, element) => {
        const updatedRegisterForm = {
            ...formElements
        };
        const updatedFormElement = {
            ...updatedRegisterForm[element]
        };
        updatedFormElement.value = event.target.value;
        updatedFormElement.valid = checkValidaty(updatedFormElement.value, updatedFormElement.validation);
        updatedFormElement.touched = true;
        updatedRegisterForm[element] = updatedFormElement;

        setFormElements(updatedRegisterForm);

        let formIsValid = true;
        for(let formElement in updatedRegisterForm) {
            formIsValid = updatedRegisterForm[formElement].valid && formIsValid;
        }
        setFormIsValid(formIsValid);
    }

    // When form is submitted
    const orderHandler = (event) => {
        event.preventDefault();
        setLoading(true);
        
        // Check form validity
        if(!formIsValid) {
            setLoading(false);
            toast.error('Vérifiez vos informations, certains champs sont mal remplis.');
        }
        else {
            // Does the informations updated?
            if(props.user.firstName == formElements.firstName.value) {
                toast.error("Modifiez d'abord vos informations.");
                setLoading(false);
            }
            else {
                // OK let's update the user's doc
                let userDoc = fire.firestore().collection('users').doc(props.user.id);
                userDoc.update({
                    firstName: formElements.firstName.value
                })
                .then(() => {
                    toast.success('Vos informations ont été modifées avec succès.');
                    props.updateUserState({...props.user, firstName: formElements.firstName.value});
                    setLoading(false);
                })
                .catch(() => {
                    toast.error('Une erreur est survenue. Merci de rééssayer plus tard.');
                    setLoading(false);
                });
            }
        }
    }

    // Array of form's elements
    const formElementsArray = [];
    for(let element in formElements) {
        formElementsArray.push({
            id: element,
            config: formElements[element]
        });
    }

    // Form
    let form = <Spinner />;
    
    if(!loading) {
        form = (
            <form onSubmit={(event) => orderHandler(event)}>
                {formElementsArray.map(formElement => (
                    <Input
                        key={formElement.id}
                        elementType={formElement.config.elementType}
                        elementConfig={formElement.config.elementConfig}
                        value={formElement.config.value}
                        invalid={!formElement.config.valid}
                        shouldValidate={formElement.config.validation}
                        errorMessage={formElement.config.errorMessage}
                        touched={formElement.config.touched}
                        label={formElement.config.label}
                        readonly={formElement.config.readonly}
                        changed={(event) => inputChangedHandler(event, formElement.id)} />
                ))}
                <div className='mt-3 text-right'>
                    <Button btnType='Continue'>Modifier mes informations</Button>
                </div>
            </form>
        );
    }

    return(
        <React.Fragment>
            <h2 className="title-display">Profil</h2>
            <div className="alert alert-primary mt-3">
                Vous souhaitez modifier votre adresse email ou votre mot de passe ? Rendez-vous dans l'onglet <i>confidentialité</i>.
            </div>
            {form}
        </React.Fragment>
    );
    
};

export default ProfilTab;