import React, { useState } from 'react';

import fire from '../../../../config/Fire';
import Spinner from '../../../../components/UI/Spinner/Spinner';
import Button from '../../../../components/UI/Button/Button';
import Input from '../../../../components/UI/Input/Input';
import { toast } from 'react-toastify';

const ConfidentialityTab = (props) => {

    // State: loading
    const [loading, setLoading] = useState(false);
    // State: form validation
    const [formIsValid, setFormIsValid] = useState(false);
    // State: form
    const [formElements, setFormElements] = useState({
        email: {
            elementType: 'input',
            elementConfig: {
                type: 'email',
                placeholder: 'Votre adresse email'
            },
            label: 'Adresse email',
            value: props.user.email,
            validation: {
                required: true
            },
            errorMessage: 'Veuillez entrer une adresse email.',
            valid: true,
            touched: false,
            readonly: false
        },
        password: {
            elementType: 'input',
            elementConfig: {
                type: 'password',
                placeholder: 'Votre mot de passe actuel'
            },
            label: 'Mot de passe actuel',
            value: '',
            validation: {
                required: true
            },
            errorMessage: 'Veuillez entrer votre mot de passe.',
            valid: false,
            touched: false,
            readonly: false
        }
    });
    // State: form password validation
    const [formPasswordIsValid, setFormPasswordIsValid] = useState(false);
    // State: form
    const [formPasswordElements, setFormPasswordElements] = useState({
        password: {
            elementType: 'input',
            elementConfig: {
                type: 'password',
                placeholder: 'Votre mot de passe actuel'
            },
            label: 'Mot de passe actuel',
            value: '',
            validation: {
                required: true
            },
            errorMessage: 'Veuillez entrer votre mot de passe.',
            valid: false,
            touched: false,
            readonly: false
        },
        newPassword: {
            elementType: 'input',
            elementConfig: {
                type: 'password',
                placeholder: 'Votre nouveau mot de passe'
            },
            label: 'Nouveau mot de passe',
            value: '',
            validation: {
                required: true
            },
            errorMessage: 'Veuillez entrer votre nouveau mot de passe.',
            valid: false,
            touched: false,
            readonly: false
        }
    });

    const checkValidaty = (value, rules) => {
        let isValid = true;

        if(rules.required) {
            isValid = value.trim() !== '' && isValid;
        }

        if(rules.minLength) {
            isValid = value.length >= rules.minLength && isValid;
        }

        if(rules.maxLength) {
            isValid = value.length <= rules.maxLength && isValid;
        }

        return isValid;
    }

    // When input is changed
    const inputChangedHandler = (event, element) => {
        const updatedRegisterForm = {
            ...formElements
        };
        const updatedFormElement = {
            ...updatedRegisterForm[element]
        };
        updatedFormElement.value = event.target.value;
        updatedFormElement.valid = checkValidaty(updatedFormElement.value, updatedFormElement.validation);
        updatedFormElement.touched = true;
        updatedRegisterForm[element] = updatedFormElement;

        setFormElements(updatedRegisterForm);

        let formIsValid = true;
        for(let formElement in updatedRegisterForm) {
            console.log(formIsValid);
            formIsValid = updatedRegisterForm[formElement].valid && formIsValid;
        }
        setFormIsValid(formIsValid);
    }

    // When form is submitted
    const orderHandler = (event) => {
        event.preventDefault();
        setLoading(true);
        
        // Check form validity
        if(!formIsValid) {
            toast.error('Vérifiez vos informations, certains champs sont mal remplis.');
        }
        else {
            // Does the informations updated?
            if(props.user.email == formElements.email.value) {
                toast.error("Modifiez d'abord vos informations.");
                setLoading(false);
            }
            else {
                // OK let's update the user's email
                fire.auth().signInWithEmailAndPassword(props.user.email, formElements.password.value)
                    .then((result) => {
                        result.user.updateEmail(formElements.email.value);
                        toast.success('Votre adresse email a été modifiée avec succès.');
                        props.updateUserState({...props.user, email: formElements.email.value});
                        setLoading(false);
                    })
                    .catch((error) => {
                        console.log(error);
                        toast.error('Une erreur est survenue. Vérifiez vos informations ou contactez-nous.');
                        setLoading(false);
                    });
            }
        }
    }

    // Array of form's elements
    const formElementsArray = [];
    for(let element in formElements) {
        formElementsArray.push({
            id: element,
            config: formElements[element]
        });
    }

    // Form
    let form = <Spinner />;
    
    if(!loading) {
        form = (
            <React.Fragment>
            <form onSubmit={(event) => orderHandler(event)}>
                {formElementsArray.map(formElement => (
                    <Input
                        key={formElement.id}
                        elementType={formElement.config.elementType}
                        elementConfig={formElement.config.elementConfig}
                        value={formElement.config.value}
                        invalid={!formElement.config.valid}
                        shouldValidate={formElement.config.validation}
                        errorMessage={formElement.config.errorMessage}
                        touched={formElement.config.touched}
                        label={formElement.config.label}
                        readonly={formElement.config.readonly}
                        changed={(event) => inputChangedHandler(event, formElement.id)} />
                ))}
                <div className='mt-3 text-right'>
                    <Button btnType='Continue'>Modifier mon adresse email</Button>
                </div>
            </form>
            </React.Fragment>
        );
    }

    // Password
    // When input is changed
    const inputPasswordChangedHandler = (event, element) => {
        const updatedPasswordForm = {
            ...formPasswordElements
        };
        const updatedFormElement = {
            ...updatedPasswordForm[element]
        };
        updatedFormElement.value = event.target.value;
        updatedFormElement.valid = checkValidaty(updatedFormElement.value, updatedFormElement.validation);
        updatedFormElement.touched = true;
        updatedPasswordForm[element] = updatedFormElement;

        setFormPasswordElements(updatedPasswordForm);

        let formIsValid = true;
        for(let formElement in updatedPasswordForm) {
            formIsValid = updatedPasswordForm[formElement].valid && formIsValid;
        }
        setFormPasswordIsValid(formIsValid);
    }

    // When form is submitted
    const passwordOrderHandler = (event) => {
        event.preventDefault();
        setLoading(true);
        
        // Check form validity
        if(!formPasswordIsValid) {
            setLoading(false);
            toast.error('Vérifiez vos informations, certains champs sont mal remplis.');
        }
        else {
            // OK let's update the user's password
            fire.auth().signInWithEmailAndPassword(props.user.email, formPasswordElements.password.value)
                .then((result) => {
                    result.user.updatePassword(formPasswordElements.newPassword.value);
                    toast.success('Votre mot de passe a été modifié avec succès.');
                    setLoading(false);
                })
                .catch((error) => {
                    console.log(error);
                    toast.error('Une erreur est survenue. Vérifiez vos informations ou contactez-nous.');
                    setLoading(false);
                });
        }
    }

    // Array of form's elements
    const formPasswordElementsArray = [];
    for(let element in formPasswordElements) {
        formPasswordElementsArray.push({
            id: element,
            config: formPasswordElements[element]
        });
    }

    // Form
    let passwordForm = <Spinner />;
    
    if(!loading) {
        passwordForm = (
            <React.Fragment>
            <form onSubmit={(event) => passwordOrderHandler(event)}>
                {formPasswordElementsArray.map(formElement => (
                    <Input
                        key={formElement.id}
                        elementType={formElement.config.elementType}
                        elementConfig={formElement.config.elementConfig}
                        value={formElement.config.value}
                        invalid={!formElement.config.valid}
                        shouldValidate={formElement.config.validation}
                        errorMessage={formElement.config.errorMessage}
                        touched={formElement.config.touched}
                        label={formElement.config.label}
                        readonly={formElement.config.readonly}
                        changed={(event) => inputPasswordChangedHandler(event, formElement.id)} />
                ))}
                <div className='mt-3 text-right'>
                    <Button btnType='Continue'>Modifier mon mot de passe</Button>
                </div>
            </form>
            </React.Fragment>
        );
    }

    return(
        <React.Fragment>
            <h2 className="title-display">Confidentialité</h2>
            <h3 className="subtitle-display">Adresse email</h3>
            {form}
            <hr className="my-5"></hr>
            <h3 className="subtitle-display">Mot de passe</h3>
            {passwordForm}
        </React.Fragment>
    );
};

export default ConfidentialityTab;