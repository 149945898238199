import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const PrivateRoute = (props) => {

    if(props.forConnected) {
        if(!props.isConnected) {
            return <Redirect to='/' />;
        }
    }
    else {
        if(props.isConnected) {
            return <Redirect to='/' />;
        }
    }

    return(
        <Route path={props.path} component={props.component} exact={props.exact} />
    );
};

export default PrivateRoute;