import React, { useEffect } from 'react';

import RegisterForm from './RegisterForm/RegisterForm';

const Register = (props) => {

    useEffect(() => {
        document.title = 'Inscrivez-vous dès maintenant | Japan-Teaching';
    }, []);

    return(
        <React.Fragment>
            <div className='container'>
                <div className='row justify-content-md-center'>
                    <div className='col-md-8 col-lg-6'>
                        <RegisterForm />
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Register;